<template>
  <div class="detail-box">
  <van-nav-bar :fixed="true" :placeholder="true" :title="title" left-arrow
@click-left="toBack"
  >
</van-nav-bar>
    <div class="detail-more" v-show="goodsList['show']">
      
      <span v-for="a in titleList" :key="a" :class="{'active':specifications==a}" @click="toggleNav(a)">{{a}}</span>

      <!-- <span :class="{'active':specifications=='生活款'}" @click="toggleNav('生活款')">生活款</span>
      <span :class="{'active':specifications=='宫廷款'}" @click="toggleNav('宫廷款')">宫廷款</span>
      <span :class="{'active':specifications=='尊我款'}" @click="toggleNav('尊我款')">尊我款</span> -->
    </div>
    <div class="detail-set-box" v-show="!goodsList['show']">
      
      <h1>{{goodsList['name']}}</h1>
      <p>暂无权限查看图片</p>
    </div>
    <div v-if="goodsList['imgList']">
    
      
      <div class="">
        <div class="detail-top-goods-type" v-for="a in goodsList['imgList'][1]" :key='a.id'>
          <img :src="a.img" alt="">
        </div>
      </div>
      <!-- <div class="detail-set-box">
        
        <h1>{{goods_name}}</h1>
        <h1>{{goods_price?'￥'+goods_price:''}}</h1>
        <h2>{{goods_info}}</h2>
      </div> -->
      <div class="">
        <div class="detail-top-goods-type" v-for="a in goodsList['imgList'][2]" :key='a.id'>
          <img :src="a.img" alt="">
        </div>
      </div>
      <div class="detail-top-goods-box">
        <div class="detail-top-goods-type" v-for="a in goodsList['imgList'][3]" :key='a.id'>
          <img :src="a.img" alt="">
        </div>
      </div>
      <div class="detail-table-box" v-if="goodsList['imgList']">
        <!-- <h1>图片集</h1> -->
        <Scroller  ref="child" :cpList="goodsList['imgList'][4]" ></Scroller>
      
      </div>

      <div class="detail-set-box2" v-show="goodsList['show']">
        <img src="../assets/images/detail/tj.png" alt="">
        <!-- <h2>推荐搭配</h2> -->
      </div>
      
      <div class="detail-top-goods-box2">
        <!-- <div class="detail-top-goods-type" v-for="a in goodsList['productCollocations']" :key='a.id' @click="selectProductById(a.collocationId,a.specifications)"> -->
        <div class="detail-top-goods-type" v-for="a in goodsList['productCollocations']" :key='a.id' @click="toGo('/detail',{'id':a.collocationId,'specifications':a.specifications})">
          <img :src="a.cover" alt="">
        </div>
      </div>
      <!-- <div class="index-swipe-box">
        <h1 class="title">密尊珠宝 - 浏览全部</h1>
        <van-swipe class="my-swipe" :autoplay="6000" indicator-color="green">
          <template v-for="(v,index) in cpList">
          <van-swipe-item  touchable='true'  :key="index" v-if="index % 2 == 0">
            <div class="my-swipe-type">
              <img :src="cpList[index].img" alt="">
              <h1>{{cpList[index].title}}</h1>
              <h2>{{cpList[index].content}}</h2>
            </div>
            <div class="my-swipe-type" v-if="cpList[index+1]">
              <img :src="cpList[index+1].img" alt="">
              <h1>{{cpList[index+1].title}}</h1>
              <h2>{{cpList[index+1].content}}</h2>
            </div>
          </van-swipe-item>
          </template>
          
        </van-swipe>
      </div> -->
      
    </div>
  </div>
</template>

<script>
import {selectProductById} from "../utils/api";
import {mapMutations} from "vuex";

export default {
  components: {
    
  },
  data() {
    return {
      typeList:['生活款','宫廷款','尊我款','男女同款'],
      title:'',
      id:'',
      specifications:'',
      show:'',
      goods_price:'',
      goods_name:'',
      goods_info:'',
      tabsactive:'1',
      active: 1,
      total: 4,
      headerShow:true,
      showNav:true,
      navShow:true,
      wrapScroll: {},
      goodsList:[],
      allGoodsList:[],
      cpList1:[],
      cpList2:[],
      cpList3:[],
      cpList4:[],
      cpList5:[],
      titleList:[],
      
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
    
    this.setBottomNav();
    // setTimeout(()=>{
    //   this.initscroll();
    // },20)
    this.id = this.$route.query.id;
    this.specifications = this.$route.query.specifications;
    this.selectProductById();
    
  },
  inject:['appReload'],
  watch: {

    '$route' (to, from) {
      this.appReload();
        // this.id = this.$route.query.id;
        // this.specifications = this.$route.query.specifications;
        // this.selectProductById();
        
    }
  },
  methods: {
    ...mapMutations(["saveBottomNav"]),
    setBottomNav(){
      this.saveBottomNav('index');
    },
    
    toggleNav(nav){
      console.log(nav)
      this.specifications=nav;
      this.goodsList=this.allGoodsList[this.specifications];
      this.title=this.goodsList['name']+' '+this.specifications;
      
      this.$refs.child.BSrefresh();
    },
   
    selectProductById(id,specifications){
      

      if(specifications)
      this.specifications = specifications;
      if(id)
      this.id = id;
      selectProductById({
        id: this.id,
      })
      .then((res) => {
        this.allGoodsList=res.data;

        let tList=[];
        this.titleList=[];
       
        for(var i in this.allGoodsList){
         
          tList.push(i);
        }
        for(var i in this.typeList){
          for(var j in tList){
            if(this.typeList[i]==tList[j]){
              this.titleList.push(this.typeList[i]);
            }
          }
        }


        this.goodsList=res.data[this.specifications];
        this.title=res.data[this.specifications]['name']+' '+this.specifications;
        
        
      })
    },
    goDetail(){
      this.$router.push("/detail");
    },
    
  },
};
</script>

<style lang="scss">
.detail-box{
  .index-swipe-box{
    border-bottom:1px solid #fff ;
    border-top:1px solid #fff ;
    padding:5vh 40px;
    background: #fff;
    .title{
      font-size: 30px;
      margin-bottom: 20px;
    }
    .my-swipe-type{
      
      width: 48%;
      display: inline-block;
      
      h1{
        padding-top: 10px;
        text-align: left;
      }
      h2{
        text-align: left;
      }
    }
    .my-swipe-type:nth-of-type(n+1){
        padding-right: 1%;
    }
    .my-swipe-type:nth-of-type(2n){
        padding-left: 1%;
    }
  }
  .my-swipe{
    padding-bottom: 60px;
  }
  .my-swipe .van-swipe__indicators{
    bottom:0px;
  }
  .my-swipe .van-swipe-item {
    width: 100%;
    color: #212121;
    font-size: 20px;
    text-align: center;
  }
  .my-swipe .van-swipe__indicator{
    width:60px;
    height:8px;
    background: #ccc;
    border-radius:2px;
  }
}
</style>
<style scoped lang="scss">
.detail-box{
  background: #f2f2f2;
  img{
        display: block;
      }
  .detail-more{
    // position: fixed;
    // top:100px;
    // left: 0;
    width: 100%;
    height: 80px;
    background: #ffea00;
    text-align: center;
    span{
      cursor: pointer;
      margin: 15px 40px 0;
      display: inline-block;
      padding:0 26px;
      height: 50px;
      line-height: 50px;
      font-size: 22px;
      color:#313131;
      &.active{
        border-radius: 46px;
        background: #fff;
        color:#f08300;
      }
    }
  }
  .detail-set-box{
    padding:5vh 40px;
    color:#616161;
    h1{
      font-size:48px;font-weight:bold;margin-bottom:20px;
    }
    h2{
      font-size:30px;line-height:2;
    }
    
  }
  .detail-set-box2{
    padding:0vh 40px 2vh;
    h2{
      font-size:34px;line-height:2;
      color:#919191;
    }
  }
  .detail-top-goods-box{
    padding:5vh 40px;
    .detail-top-goods-type{
      margin: 100px 0;
      border:1px solid #f5f5f5;
      
    }
    
  }
  .detail-top-goods-box2{
    padding:5vh 40px;
    display: flex;
    flex-wrap:wrap;
    justify-content:space-between;
    .detail-top-goods-type{
      width: 48%;
      border:1px solid #f5f5f5;
      margin-bottom: 3%;
    }
    
  }
  
  .detail-table-box{
    
    padding:5vh 40px;
    h1{
      font-size: 30px;
      margin-bottom: 20px;
    }
    .wrapper {
      position: relative;
      width: 100%;
      height: 700px;
      overflow: hidden;
    }
    .content{
      width: 2500px;
    }

    .content li{
      display: inline-block;
      width: 500px;
      height: 700px;
      margin-right:20px ;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  
}
</style>
